<template>
	<section id="common_author_area" class="section_padding">
		<div class="container">
			<div class="row">
				<div class="col-lg-8 offset-lg-2">
					<div class="common_author_boxed">
						<div class="common_author_heading">
							<h2>{{ $t("sign_up") }}</h2>
						</div>
						<div class="common_author_form">
							<form @submit.prevent="registerUser" id="main_author_form">
								<div class="form-group">
									<input
										type="text"
										class="form-control"
										:placeholder="$t('name')"
										v-model="formData.name"
										required
									/>
								</div>
								<div class="form-group">
									<input
										type="email"
										class="form-control"
										:placeholder="$t('email')"
										v-model="formData.email"
										required
									/>
								</div>
								<div class="form-group">
									<input
										type="password"
										class="form-control"
										:placeholder="$t('password')"
										v-model="formData.password"
										required
									/>
								</div>
								<div class="form-group">
									<input
										type="password"
										class="form-control"
										:placeholder="$t('confirm_password')"
										v-model="formData.confirmPassword"
										required
									/>
								</div>
								<div class="form-check write_spical_check">
									<input
										class="form-check-input"
										type="checkbox"
										v-model="formData.acceptTerms"
										id="flexCheckDefaultf1"
									/>
									<label
										class="form-check-label"
										for="flexCheckDefaultf1"
										@click="openInNewTab()"
									>
										<span class="main_spical_check">
											<span />
											{{ $t("i_accept_terms_and_conditions") }}
										</span>
									</label>
								</div>

								<div class="common_form_submit">
									<button type="submit" class="btn btn_theme btn_md">
										{{ $t("register") }}
									</button>
								</div>
								<div class="have_acount_area other_author_option">
									<p>
										{{ $t("already_have_account") }}
										<router-link to="/login">{{
											$t("log_in_now")
										}}</router-link>
									</p>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import axios from "axios";
	import { mapActions } from "vuex";
	import { API_BASE_URL, REGISTER_OWNER_API } from "@/api-config";
	import Swal from "sweetalert2";

	export default {
		name: "CommonAuthorThree",
		data() {
			return {
				formData: {
					name: "",
					email: "",
					password: "",
					confirmPassword: "",
					acceptTerms: false,
				},
			};
		},
		computed: {
			formatDate() {
				if (!this.formData.birthDate) return "";
				const [year, month, day] = this.formData.birthDate.split("-");
				return `${year}/${month}/${day}`;
			},
		},
		methods: {
			...mapActions("form", ["updateError"]),

			showAlert(message, type) {
				Swal.fire({
					icon: type,
					title: type === "success" ? "Éxito" : "Error",
					text: message,
				}).then(() => {
					this.updateError({ status: false, message: "" });
				});
			},

			validateForm() {
				if (this.formData.password !== this.formData.confirmPassword) {
					return this.$t("passwords_do_not_match");
				}
				if (!this.formData.acceptTerms) {
					return this.$t("please_accept_terms_and_conditions");
				}
				return null;
			},

			async registerUser() {
				const validationError = this.validateForm();
				if (validationError) {
					this.showAlert(validationError, "error");
					return;
				}
				try {
					const response = await axios.post(
						`${API_BASE_URL}${REGISTER_OWNER_API}`,
						{
							name: this.formData.name,
							email: this.formData.email,
							password: this.formData.password,
							password_confirmed: this.formData.confirmPassword,
						}
					);

					if (!response.data.errors) {
						this.showAlert("Registration successful!", "success");
						this.$router.push("/login");
					}
				} catch (error) {
					if (
						error.response &&
						error.response.data &&
						error.response.data.message
					) {
						const errorMessage = error.response.data.message;
						this.showAlert(errorMessage, "error");
					} else {
						this.showAlert("Error desconocido");
					}
				}
			},

			openInNewTab() {
				const url = this.$router.resolve({
					name: "terms-service",
				}).href;

				window.open(url, "_blank", "noopener,noreferrer");
			},
		},
	};
</script>
