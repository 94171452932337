<template>
	<section id="theme_search_form" style="position: absolute-center; z-index: 2">
		<div class="container">
			<div class="row">
				<div class="col-lg-12">
					<div class="theme_search_form_area">
						<div class="row">
							<div class="col-lg-12">
								<div class="tour_search_form">
									<form @submit.prevent="propertiesSearch">
										<div class="row">
											<div class="col-lg-4 col-md-12 px-0 col-sm-12 col-12">
												<div class="flight_Search_boxed py-3">
													<p>{{ $t("destination") }}</p>
													<v-select
														v-model="selectedOption"
														label="text"
														:options="filteredOptions"
														:filterable="true"
														:placeholder="$t('where_do_you_want_to_go')"
														@search="handleSearch"
														@input="updateSelectedOptionId"
													>
													<template #no-options="{ search }">
														<span v-if="search.length <= 2">{{ $t('Please_enter_least_2_characters')}}</span>
														<span v-else>{{ $t('no_results_found') }}</span>
													</template>

												</v-select>
												</div>
											</div>
											<div class="col-lg-4 col-md-12 col-sm-12 col-12">
												<div class="form_search_date">
													<div class="flight_Search_boxed py-3 date_flex_area">
														<div class="Journey_date">
															<p>{{ $t("dates_of_stay") }}</p>
															<flat-pickr
																v-model="selectedDates"
																:config="dateConfig"
																:placeholder="
																	$t('entry_date') +
																	' - ' +
																	$t('departure_date')
																"
																class="w-100"
																@onChange="handleDateChange"
															/>
														</div>
													</div>
												</div>
											</div>
											<CountDropdownHotel
												:props_nationality="props_nationality"
												:props_guests="props_guests"
												@updateGuests="handleUpdateGuests"
												@updatecustomerNationality="setcustomerNationality"
											/>
											<div class="col-lg-2 col-md-6 col-sm-12 col-12">
												<div
													@click="propertiesSearch"
													:class="[
														'btn',
														'search',
														'btn_theme',
														'd-flex',
														'align-items-center',
														'justify-content-center',
														'flight_Search_boxed',
														{ disabled: isLoading },
													]"
													:disabled="isLoading"
												>
													{{ $t("search") }}
													<i
														v-if="!isLoading"
														class="px-2 fas fa-angle-right text-coral"
													></i>
													<div v-else class="loader"></div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import CountDropdownHotel from "@/components/home/CountDropdownHotel.vue";
	import Swal from "sweetalert2";
	import axios from "axios";
	import { mapState, mapActions } from "vuex";
	import { API_BASE_URL, COUNTRY_STATE_CITY_API } from "@/api-config";
	import FlatPickr from "vue-flatpickr-component";
	import "flatpickr/dist/flatpickr.css";
	import { Spanish } from "flatpickr/dist/l10n/es.js";

	export default {
		name: "Form",
		components: {
			CountDropdownHotel,
			FlatPickr,
		},
		props: {
			props_destiny: {
				type: Object,
				default: null,
			},
			props_guests: {
				type: Array,
				default: null,
			},
			props_nationality: String,
			props_start_date: Date,
			props_end_date: Date,
			props_adults: Number,
			props_children: Array,
			props_city: String,
			props_country: String,
			props_rooms: Number,
		},
		data() {
			return {
				destination: "",
				active: false,
				searchText: "",
				results: {
					cities: [],
					countries: [],
					properties: [],
					allOptions: [],
				},
				start_date: "",
				end_date: "",
				selectedOption: "",
				city: "",
				country: "",
				national: {},
				guests: [],
				isLoading: false,
				selectedDates: [],
				dateConfig: {
					locale: Spanish,
					mode: "range",
					showMonths: 2,
					formatDate: (date) => {
						return date.toISOString().slice(0, 10);
					},
					defaultDate: this.selectedDates,
				},
			};
		},
		watch: {
			props_start_date: {
				immediate: true,
				handler(newValue) {
					if (newValue) this.selectedDates[0] = new Date(newValue);
				},
			},
			props_end_date: {
				immediate: true,
				handler(newValue) {
					if (newValue) this.selectedDates[1] = new Date(newValue);
				},
			},
		},
		mounted() {
			this.getCountryStateCity();
			this.start_date = this.props_start_date;
			this.end_date = this.props_end_date;
			this.city = this.props_city;
			this.country = this.props_country;
			this.rooms = this.props_rooms;
			if (this.props_destiny != null) {
				this.selectedOption = this.props_destiny;
			}

			this.$nextTick(() => {
				if (this.props_start_date) {
					this.selectedDates[0] = new Date(this.props_start_date);
				}
				if (this.props_end_date) {
					this.selectedDates[1] = new Date(this.props_end_date);
				}
			});
		},

		computed: {
			...mapState({
				adults: (state) => state.form.adults,
				children: (state) => state.form.children,
				guests: (state) => state.form.guests,
				city: (state) => state.form.city,
				country: (state) => state.form.country,
				customerNationality: (state) => state.form.customerNationality,
				error: (state) => state.form.error,
			}),

			filteredOptions() {
				if (!this.searchText || this.searchText.length < 2) {
					return [];
				}
				return this.results.allOptions.filter((option) =>
					option.text.toLowerCase().includes(this.searchText.toLowerCase())
				);
			},
		},

		methods: {
			...mapActions("form", ["updateGuests", "fetchProperties", "updateError"]),

			handleUpdateGuests(guestData) {
				this.guests = guestData;
			},

			handleDateChange(selectedDates) {
				if (selectedDates.length === 2) {
					const formatDate = (date) => {
						const d = new Date(date);
						const year = d.getFullYear();
						const month = String(d.getMonth() + 1).padStart(2, "0");
						const day = String(d.getDate()).padStart(2, "0");
						return `${year}-${month}-${day}`;
					};

					this.start_date = formatDate(selectedDates[0]);
					this.end_date = formatDate(selectedDates[1]);
				}
			},

			setcustomerNationality(customerNationality) {
				this.national = customerNationality;
			},

			showAlert(message, type) {
				Swal.fire({
					icon: type,
					title: type === "success" ? "Éxito" : "Error",
					text: message,
				}).then((confirm) => {
					this.updateError({ status: false, message: "" });
				});
			},

			getCountryStateCity() {
				axios
					.get(`${API_BASE_URL}${COUNTRY_STATE_CITY_API}`)
					.then((response) => {
						this.results.cities = response.data.cities;
						// this.results.countries = response.data.countries;
						this.results.properties = response.data.properties;

						this.results.allOptions = [
							...this.results.cities,
							// ...this.results.countries,
							...this.results.properties,
						];
					})
					.catch((error) => {
						console.log(error);
					});
			},

			handleSearch(searchTerm) {
				this.searchText = searchTerm;
			},

			updateSelectedOptionId(selectedOption) {
				if (selectedOption && selectedOption.id) {
					this.selectedOptionId = selectedOption.id;
					this.destination = selectedOption.text;
				} else {
					this.selectedOptionId = null;
					this.destination = "";
				}
			},

			async propertiesSearch() {
				this.isLoading = true;

				let queryParameters = {
					start_date: this.start_date,
					end_date: this.end_date,
					rooms: 0,
					city: this.city,
					country: this.country,
					customerNationality: this.national.slug,
					guests: this.guests,
				};

				queryParameters.rooms = queryParameters.guests.length;

				const serializeGuests = (guestsArray) => {
					return guestsArray
						.map(
							(guest) =>
								`adults=[${guest.adults}],children=[${guest.children.join(
									","
								)}]`
						)
						.join("&");
				};

				const serializedGuests = serializeGuests(queryParameters.guests);

				if (this.selectedOption) {
					const optionTypes = ["cities", "countries", "properties"];
					const optionType = optionTypes.find((type) =>
						this.results[type].includes(this.selectedOption)
					);
					if (optionType) {
						const selectedOptionId = this.selectedOption.id;

						if (optionType === "cities") {
							const [cityPart, countryPart] = selectedOptionId.split("*#");
							queryParameters.city = cityPart ? cityPart.split("=")[1] : "";
							queryParameters.country = countryPart
								? countryPart.split("=")[1]
								: "";

							try {
								await this.fetchProperties(queryParameters);

								if (this.error.status === true) {
									this.showAlert(this.error.message, "error");
								} else {
									let queryParams = {
										start_date: this.start_date,
										end_date: this.end_date,
										rooms: queryParameters.rooms,
										city: queryParameters.city,
										country: queryParameters.country,
										customerNationality: this.national.slug,
										guests: serializedGuests,
										destiny_id: this.selectedOption.id,
										destiny_slug: this.selectedOption.slug,
										destiny_text: this.selectedOption.text,
									};
									console.log(queryParameters);
									this.$router.push({
										name: "hotel-search",
										query: queryParams,
									});
								}
							} catch (error) {
								console.error("Error fetching properties:", error);
							} finally {
								this.isLoading = false;
							}
						}

						if (optionType === "countries") {
							const [countryPart] = selectedOptionId.split("*#");
							queryParameters.country = countryPart
								? countryPart.split("=")[1]
								: "";

							try {
								await this.fetchProperties(queryParameters);

								if (this.error.status === true) {
									this.showAlert(this.error.message, "error");
								} else {
									let queryParams = {
										start_date: this.start_date,
										end_date: this.end_date,
										rooms: queryParameters.rooms,
										country: queryParameters.country,
										customerNationality: this.national.slug,
										guests: serializedGuests,
										destiny_id: this.selectedOption.id,
										destiny_slug: this.selectedOption.slug,
										destiny_text: this.selectedOption.text,
									};
									console.log(queryParameters);
									this.$router.push({
										name: "hotel-search",
										query: queryParams,
									});
								}
							} catch (error) {
								console.error("Error fetching properties:", error);
							} finally {
								this.isLoading = false;
							}
						}

						if (optionType === "properties") {
							queryParameters.property_id = selectedOptionId;
							queryParameters.key = this.selectedOption.slug;

							let queryParams = {
								start_date: this.start_date,
								end_date: this.end_date,
								rooms: queryParameters.rooms,
								guests: serializedGuests,
								customerNationality: this.national.slug,
							};
							console.log(queryParameters);
							this.$router.push({
								name: "hotel-details",
								params: {
									key: queryParameters.key,
									id: selectedOptionId,
								},
								query: queryParams,
							});
						}
					}
				}
			},
		},
	};
</script>
<style>
	.loader {
		border: 4px solid #f3f3f3;
		border-top: 4px solid #3498db;
		border-radius: 50%;
		width: 24px;
		height: 24px;
		animation: spin 1s linear infinite;
		margin: auto;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
