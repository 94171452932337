<template>
	<div class="col-lg-2 col-md-6 col-sm-12 col-12">
		<div class="flight_Search_boxed py-3 dropdown_passenger_area">
			<p>{{ $t("guests") }}</p>

			<div class="dropdown">
				<button
					class="dropdown-toggle final-count"
					data-toggle="dropdown"
					type="button"
					id="dropdownMenuButton1"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					{{ number }} {{ $t("guests") }}
				</button>

				<div
					class="dropdown-menu dropdown_passenger_info"
					aria-labelledby="dropdownMenuButton1"
					:class="{ show: isDropdownOpen }"
				>
					<div class="traveller-calulate-persons">
						<div class="passengers">
							<h6>{{ $t("guests") }}</h6>
							<div class="mt-2">
								<v-select
									v-model="customerNationalitySelected"
									label="text"
									class="nationality"
									:options="filteredNationalities"
									:filterable="false"
									:placeholder="$t('nationality')"
									@input="emitcustomerNationality"
									readonly="true"
								></v-select>
							</div>
							<div
								class="border-bottom py-2"
								v-for="(guest, index) in guests"
								:key="index"
							>
								<div class="passengers-types">
									<div class="passengers-type">
										<div class="text">
											<span class="count pcount">{{ guest.adults }}</span>
											<div class="type-label">
												<p>{{ $t("adults") }}</p>
												<span>{{ $t("more_than_18_years") }}</span>
											</div>
										</div>
										<div class="button-set">
											<button
												type="button"
												class="btn-add"
												@click.stop="increment(index)"
											>
												<i class="fas fa-plus"></i>
											</button>
											<button
												type="button"
												class="btn-subtract"
												@click.stop="decrement(index)"
												:disabled="guest.adults === 1"
											>
												<i class="fas fa-minus"></i>
											</button>
										</div>
									</div>
									<div class="passengers-type">
										<div class="text">
											<span class="count ccount">{{
												guest.children.length
											}}</span>
											<div class="type-label">
												<p class="fz14 mb-xs-0">{{ $t("children") }}</p>
												<span>{{ $t("less_than_18_years") }}</span>
											</div>
										</div>
										<div class="button-set">
											<button
												type="button"
												class="btn-add-c"
												@click.stop="incrementc(index)"
											>
												<i class="fas fa-plus"></i>
											</button>
											<button
												type="button"
												class="btn-subtract-c"
												@click.stop="decrementc(index)"
												:disabled="guest.children.length === 0"
											>
												<i class="fas fa-minus"></i>
											</button>
										</div>
									</div>
								</div>

								<div
									v-for="(age, cIndex) in guest.children"
									:key="cIndex"
									class="child-age-select"
								>
									<span>{{ $t("child_age", { number: cIndex + 1 }) }}</span>
									<select
										v-model.number="guest.children[cIndex]"
										class="form-select"
									>
										<option v-for="n in 18" :key="n" :value="n - 1">
											{{ n - 1 }} {{ n - 1 == 1 ? $t("year") : $t("years") }}
										</option>
									</select>
								</div>
							</div>
							<div class="row py-1">
								<div class="col-6">
									<h6 class="btn" @click.stop="addRoom">
										{{ $t("add_room") }}
									</h6>
								</div>
								<div class="col-6 text-end">
									<h6
										class="btn"
										v-if="guests.length > 1"
										@click.stop="removeRoom"
									>
										{{ $t("delete_room") }}
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import { mapActions } from "vuex";
	import axios from "axios";
	import { API_BASE_URL, COUNTRY_API } from "@/api-config";
	export default {
		name: "CountDropdownHotel",
		props: {
			totalGuests: {
				type: Number,
				default: 0,
			},
			props_guests: {
				type: Array,
				default: null,
			},
			props_nationality: String,
		},
		data() {
			return {
				isDropdownOpen: false,
				position: {},
				countries: [],
				customerNationalitySelected: null,
				searchcustomerNationality: "",
				number: 0,
				pnumber: 0,
				cnumber: 0,
				childrenAges: [],
				national: {},
				guests: [{ adults: 0, children: [] }],
			};
		},
		mounted() {
			this.getCountries();
			this.number = this.totalGuests;
			this.updateGuestCount();
			if (this.props_guests != null) {
				this.number = this.props_guests.reduce(
					(total, props_guests) =>
						total + props_guests.adults + props_guests.children.length,
					0
				);
				this.guests = this.props_guests;
				const guestData = this.guests;
				this.$emit("updateGuests", guestData);
			}
		},
		computed: {
			filteredNationalities() {
				if (this.searchcustomerNationality) {
					return this.countries.filter((country) =>
						country.text
							.toLowerCase()
							.includes(this.searchcustomerNationality.toLowerCase())
					);
				}
				return this.countries;
			},
		},
		watch: {
			totalGuests: {
				handler(newVal) {
					this.number = newVal;
					this.updateGuestCount();
				},
				immediate: true,
			},

			customerNationalitySelected: {
				handler(newVal) {
					this.emitcustomerNationality(newVal);
				},
				deep: true,
			},
		},
		methods: {
			...mapActions("form", ["updateCapacity"]),

			updateGuestCount() {
				this.number = this.guests.reduce(
					(total, guest) => total + guest.adults + guest.children.length,
					0
				);

				const guestData = this.guests;
				this.$emit("updateGuests", guestData);
			},

			emitcustomerNationality() {
				this.$emit(
					"updatecustomerNationality",
					this.customerNationalitySelected
				);
			},

			getCountries() {
				axios
					.get(`${API_BASE_URL}${COUNTRY_API}`)
					.then((response) => {
						this.countries = response.data.results;
						if (this.props_nationality != "") {
							let nationalitySelected = this.countries.find(
								(nat) => nat.slug === this.props_nationality
							);
							if (nationalitySelected) {
								this.customerNationalitySelected = nationalitySelected;
							} else {
								console.log("No se encontró el país con ese slug.");
							}
						}
					})
					.catch((error) => {
						console.log(error);
					});
			},

			addRoom(event) {
				event.stopPropagation();
				const newRoom = {
					adults: 0,
					children: [],
				};
				this.guests.push(newRoom);
			},

			removeRoom(event) {
				event.stopPropagation();
				this.guests.pop();
			},

			increment(index) {
				this.guests[index].adults++;
				this.updateGuestCount();
			},

			decrement(index) {
				if (this.guests[index].adults === 0) return;
				this.guests[index].adults--;
				this.updateGuestCount();
			},

			incrementc(index) {
				this.guests[index].children.push(null);
				this.updateGuestCount();
			},

			decrementc(index) {
				if (this.guests[index].children.length === 0) return;
				this.guests[index].children.pop();
				this.updateGuestCount();
			},
		},
	};
</script>

<style scoped>
	.child-age-select {
		margin-top: 10px;
	}
	.bg_input {
		background-color: transparent !important;
	}
</style>
